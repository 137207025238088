import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Stack, Typography, Chip, Link, Box } from "@mui/material";
import { convertUTCDateTimeToLocalDate, convertUTCDateTimeToLocalTime, mapMedicalDocumentsStatusToChipClass, mapMedicalDocumentsStatusToLabel } from "../common";
import { getDocumentFilesApi } from "../api";
import { useSelector, useDispatch } from "react-redux";
import { setDocumentFiles, setPdfPanelOpen } from "../redux/slices/demandDomSlice";
import moment from "moment";
import StatusChip from "../CommonComponents/Chips/StatusChip";

const MedicalDocumentsTable = ({ documentData, user, setCurrentPage, setCurrentPageTrigger, currentPageTrigger, currentPage, documentDataLoading, setCurrentBoundingBoxes, currentBoundingBoxes }) => {
    const dispatch = useDispatch();
    const documentId = documentData.documentId;
    const [awaitingNavigation, setAwaitingNavigation] = useState(false);

    const { documentFiles, documentFilesLoading, pdfPanelOpen, pdfInstance } = useSelector(state => state.DemandDom);
    
    const demandOrCoverLetter = (detectedType) => detectedType === "cover letter" || detectedType === "demand letter";
    const statusDisplay = ({ fileEntityData }) => {
        if (demandOrCoverLetter(fileEntityData.detectedType) || (fileEntityData?.medicalExtractionStatus === "MedicalsComplete" && fileEntityData?.medicalTreatments?.length === 0)) {
            return 'none';
        }
        else if (!fileEntityData?.medicalExtractionStatus) {
            return 'uploading';
        } else {
            return fileEntityData?.medicalExtractionStatus;
        }
    }

    const rows = documentFiles.map((file, index) => {
        return {
            id: index + 1,
            name: file?.fileEntityData?.aliasFileName || file?.fileEntityData?.fileName,
            detectedType: file?.fileEntityData?.detectedType || '',
            extraction: statusDisplay(file),
            startPage: file?.fileEntityData?.startPage || 0,
            endPage: file?.fileEntityData?.endPage || 0,
            createdDate: file?.createdDate ? moment.utc(file?.createdDate) : ''
        }
    });

    useEffect(() => {
        if (documentId) {
            getDocumentFilesApi(documentId, user)
                .then(response => response.json())
                .then(data => dispatch(setDocumentFiles(data)))
                .catch(error => console.log('error', error));
        }
    }, [documentId, user])

    useEffect(() => {
        if (awaitingNavigation && pdfPanelOpen && pdfInstance) {
            setTimeout(() => {
                setAwaitingNavigation(false);
                setCurrentPage(currentPage);
                setCurrentPageTrigger(currentPageTrigger + 1);
            }, 3000);
        }
    }, [pdfPanelOpen, pdfInstance]);

    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: 375,
            renderCell: (params) => {
                const handleNavigate = () => {
                    setCurrentPage(params.row.startPage - 1);
                    setCurrentPageTrigger(currentPageTrigger + 1);
                    currentBoundingBoxes.length > 0 && setCurrentBoundingBoxes([]);
                    if (!pdfPanelOpen) {
                        setAwaitingNavigation(true);
                        dispatch(setPdfPanelOpen(true));
                    }
                };

                return (
                    <Stack>
                        {params?.row?.startPage ?
                            <Link component="button" onClick={handleNavigate} style={{ textDecoration: 'none', textAlign: 'left' }}>
                                <Typography variant="tableP1" style={{ color: '#4077B0' }}>
                                    {params.row.name}
                                </Typography>
                            </Link>
                            :
                            <Typography variant="tableP1">
                                {params.row.name}
                            </Typography>
                        }
                    </Stack>
                );
            },

        },
        {
            field: "extraction",
            headerName: "Status",
            width: 250,
            valueGetter: (params) => mapMedicalDocumentsStatusToLabel(params.row.extraction),
            renderCell: (params) => (
                <StatusChip
                    type='file'
                    showInfoIcon={params.row.extraction == 'MedicalsInProgress'}
                    documentStatus={params.row.extraction}
                    showEditOffIcon={false}
                />
            ),
        },
        {
            field: "createdDate",
            headerName: "Uploaded",
            width: 150,
            renderCell: (params) => {
                const { createdDate } = params.row;
                return (
                    <Stack>
                        <Typography variant="tableP1">
                            {createdDate ? convertUTCDateTimeToLocalDate(createdDate) : ''}
                        </Typography>
                        <Typography variant="tableP2">
                            {createdDate ? convertUTCDateTimeToLocalTime(createdDate) : ''}
                        </Typography>
                    </Stack>
                );
            },
        },
    ];

    const columnsWithOptions = columns.map((column) => {
        return {
            ...column,
            resizable: true,
            editable: false,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            disableColumnSelector: true,
        }
    });

    return (
        <Box sx={{ flex: 1, position: 'relative' }}>
            <Box sx={{ position: 'absolute', inset: 0 }}>
                <DataGridPro
                    loading={!!documentDataLoading || documentFilesLoading}
                    rows={rows}
                    columns={columnsWithOptions}
                    disableRowSelectionOnClick
                    disableColumnReorder
                    getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd'}
                />
            </Box>
        </Box>
    );
}

export default MedicalDocumentsTable;
