import { createSlice } from '@reduxjs/toolkit';
import pdfApi from '../../services/pdf';
//getPdfUrl

const originalDemandPdfState = {
    demandS3Url: '',
    demandS3UrlLoading: {}
};

export const DemandPdfSlice = createSlice({
    name: 'DemandPdf',
    initialState: originalDemandPdfState,
    reducers: {
        setDemandS3Url: (state, action) => {
            state.demandS3Url = action.payload;
        },
        setDemandS3UrlLoading: (state, action) => {
            console.log('action.payload', action.payload);
            state.demandS3UrlLoading = {
                ...state.demandS3UrlLoading,
                ...action.payload
            }
        },
    },
    extraReducers: (builder) => {
        builder  
        .addMatcher(pdfApi.endpoints.getPdfUrl.matchPending, (state, action) => {
            state.demandS3Url = '';
            // state.demandS3UrlLoading[action.meta.arg.originalArgs] = true;
            state.demandS3UrlLoading = {
                ...state.demandS3UrlLoading,
                [action.meta.arg.originalArgs]: true
            }
        })

        .addMatcher(pdfApi.endpoints.getPdfUrl.matchFulfilled, (state, action) => {
            state.demandS3Url = action.payload.demandS3Url;
            // state.demandS3UrlLoading[action.meta.arg.originalArgs] = false;
            state.demandS3UrlLoading = {
                ...state.demandS3UrlLoading,
                [action.meta.arg.originalArgs]: false
            }
        })

        .addMatcher(pdfApi.endpoints.getPdfUrl.matchRejected, (state, action) => {
            // state.demandS3UrlLoading[action.meta.arg.originalArgs] = false;
            state.demandS3UrlLoading = {
                ...state.demandS3UrlLoading,
                [action.meta.arg.originalArgs]: false
            }
        })

    }
});

export const {
    setDemandS3Url,
    setDemandS3UrlLoading,
} = DemandPdfSlice.actions;

export default DemandPdfSlice.reducer;